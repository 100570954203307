<template>
  <div class="main">
    <nyheads />
    <div class="ny-nav">
      <router-link to="/">论文助手</router-link>&nbsp;&nbsp;>&nbsp;&nbsp;查重
    </div>
    <div class="ny-con">
      <div class="cc-left">
        <div class="cc-left-dbxx">
          <div class="cc-left-dbxx-wzxq">文章详情</div>
          <div class="cc-left-dbxx-btn"><el-button type="primary" link @click="comeBackCompany">返回</el-button></div>
        </div>
        <div class="cc-wz" v-html="duanluo" @click="clickjuzi($event)"></div>
      </div>
      <div class="cc-right">
        <div :class="classname">
          <div class="cc-xs-main">相似率：{{fhobj.jcscore}}</div>
          <div class="cc-xs-list" v-for="(item, key) in xiangsijuzi" :key="key">
            <div class="cc-xs-list-title">
              <div class="cc-xs-list-titles">提交句</div>
              <div class="cc-xs-list-title-con">{{ item.StrXj }}</div>
            </div>
            <div class="cc-xs-list-xs">
              <div class="cc-xs-list-xs-title">相似句</div>
              <div
                class="cc-xs-list-xs-list"
                v-for="(e, i) in item.SimilarSentenceList"
                :key="i"
              >
                {{ i + 1 }}.{{ e.Sentence }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref,onMounted ,onBeforeMount} from "vue";
import { useRouter } from "vue-router";
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from "@element-plus/icons-vue";

const router = useRouter();
// 段落
let duanluo = ref("");
// 返回的对象
let fhobj=reactive({})
// 返回的数据
let fanhuishuju = reactive([]);
// 相似句子集合
let xiangsijuzi = reactive([]);
// 滚动
let classname=ref('cc-xs')
let x = ref(0);
let y = ref(0);
const onScroll = () => {
  x.value = document.documentElement.scrollLeft;
  y.value = document.documentElement.scrollTop;

  if (y.value > 142) {
    classname.value = "cc-xs2";
  } else {
    classname.value = "cc-xs";
  }
};
onMounted(() => {
  window.addEventListener("scroll", onScroll, true);
});
onBeforeMount(() => {
  window.removeEventListener("scroll", onScroll);
});

// 获取
const getlist = (pg) => {
  get("/api/Lw/GetUserArticleTest", {
    id: router.currentRoute.value.query.id,
  })
    .then((res) => {
      // console.log(JSON.parse(res.data.jcnr));
      Object.assign(fhobj,res.data)
      fanhuishuju.push(...JSON.parse(res.data.jcnr));
      let duanluotips = 1; //段落
      // 生成段落
      fanhuishuju.forEach((e, i) => {
        let duanluotemp = "";
        //未找到相似的直接拼
        if (e.XiaojuList.length == 0) {
          duanluotemp += e.Sentence;
        } else {
          duanluotemp += `<span class="xianshi-lunwen-wenzhangs" id=${i}>${e.Sentence}</span>`;
        }
        // 是否分段
        if (e.Dlid == duanluotips) {
          duanluo.value += duanluotemp;
        } else {
          duanluo.value += `<br><br>${duanluotemp}`;
          duanluotips++;
        }
      });
    })
    .catch((err) => {
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};
getlist();

// 点击段落
const clickjuzi = (event) => {
  if (event.target.id && event.target.innerHTML != "") {
    // 找到相似片段对象
    let fhtemp = fanhuishuju.filter((e, i) => {
      return i == parseInt(event.target.id);
    })[0];
    xiangsijuzi.length = 0;
    xiangsijuzi.push(...fhtemp.XiaojuList);
  }
};

// 返回
let comeBackCompany = () => {
  router.back();
  //也可以这样写：
  //router.go(-1);
};
</script>

<style scoped>
:deep(.xianshi-lunwen-wenzhangs) {
  color: #f00;
  cursor: pointer;
}
:deep(.xianshi-lunwen-wenzhangs:hover) {
  text-decoration: underline;
}
.main {
  background: #ebedf3;
}
.ny-nav {
  width: 1600px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888;
}
.ny-nav a:link,
.ny-nav a:visited {
  color: #888;
  text-decoration: none;
}
.ny-nav a:hover {
  color: #2489f2;
}
.ny-con {
  width: 1600px;
  height: auto;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  border: 1px solid #dddfe5;
  border-radius: 2px;
  padding: 30px;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 50px;
  font-size: 14px;
}
.ny-con:after {
  content: "";
  display: table;
  clear: both;
}
.cc-left {
  float: left;
  width: 769px;
  height: auto;
  box-sizing: border-box;
  padding: 20px 20px 20px 0;
  border-right: 1px solid #ccc;
}
.cc-right {
  float: right;
  width: 769px;
  height: auto;
  box-sizing: border-box;
  padding: 20px 0 20px 20px;
}
.cc-xs2{
    width: 749px;
    position: fixed;
    top: 20px;
    right: calc(50% - 769px);
}
.cc-xs{
  width: 100%;
  position:relative;
}
.cc-xs-list {
  border-bottom: 1px solid #ccc;
  margin: 0 0 20px 0;
}
.cc-xs-list-titles {
  font-weight: bold;
  margin-bottom: 5px;
}
.cc-xs-list-xs-title {
  font-weight: bold;
  margin-bottom: 5px;
}
.cc-xs-list-xs {
  margin-bottom: 10px;
}
.cc-xs-list-title {
  margin-bottom: 10px;
}
.cc-xs-list-xs-list {
  margin-bottom: 10px;
}
.cc-xs-main{
  font-weight: bold;
  margin-bottom: 20px;
}
.cc-left-dbxx{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding-bottom:20px;
}
</style>
